/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';

/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}

.wrap {
  padding: 30px 0;
}

.container-fluid.but-fixed {
  max-width: 1230px;
}

.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #fff;
  color: #999999;
  border-top: 1px solid #9cf;

  p {
    margin: 0 0 5px 0;
  }

  a {
    color: #999999;
  }
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  &:active,
  &:hover {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;

  a.logo {
    float: left;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    width: 80%;
    max-width: 450px;

    img {
      display: inline-block;
      margin-right: 5px;
    }

    span {
      display: inline-block;
      margin-top: 3px;
      font-size: 27px;
      line-height: 0.75;
      font-family: 'Open Sans', Verdana;
      font-weight: 700;
      color: #045a3f;

      &.com {
        font-size: 20px;
      }

      .small {
        margin-top: 0;
        font-size: 17px;
        font-family: 'Open Sans', Verdana;
        font-weight: normal;
        color: #555;
      }
    }
  }

  #support {
    float: right;
    margin: 0 0 0 0;
    width: auto;

    p {
      float: right;
      margin: 2px 12px 0 0;

      strong {
        font-size: 16px;
      }
    }

    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;

      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }

      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }

    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }

    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;

      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

.no-bg {
  background: none !important;
}

/* ----------------------------------------------------------------------------
	Start Page Styles
----------------------------------------------------------------------------- */
html {
  background: #fff;
}

#content-wrap {
  background: #f1f1f1 url(../images/content_wrap_bg.jpg) repeat-x top center;
}

#header-wrap {
  #phones {
    background: #06c;
    font-size: 0.9em;
  }
}

.red {
  color: $red;
}

.green {
  color: $green;
}

.bg-light {
  background: #f2f2f2 !important;
}

.border-light {
  border-color: #ebebeb !important;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

/* ACCORDION */
.accordion.accordion-custom {
  padding: 10px 10px 5px 10px;
  border-top: 1px dotted #ccc;

  .accord-content {
    display: none;
    margin-top: 10px;
  }

  &:last-child {
    border-bottom: 1px dotted #ccc;
  }

  h2,
  h3 {
    margin-left: 20px;
    padding-right: 20px;
    position: relative;
    cursor: pointer;
    color: #06c;

    &:before {
      color: #e94c48;
      content: '\e842';
      display: inline-block;
      font-family: 'icons';
      font-size: 12px;
      font-weight: normal !important;
      height: inherit;
      margin-left: -18px;
      vertical-align: middle;
      width: 18px;
      position: relative;
      top: -1px;
    }

    &.active {
      &:before {
        content: '\e843';
      }
    }

    &.noaccord {
      margin-left: 0;
      padding-right: 0;
      cursor: text;

      &:before,
      &:after {
        content: '';
      }
    }
  }
}

/* ----------------------------------------------------------------------------
	Inner Pages
----------------------------------------------------------------------------- */
#banner.home {
  margin: 10px 0;
  background: #fff;
  background: linear-gradient(to bottom, #cfe7ff 0%, #ffffff 100%);
  border-radius: 4px;
  border: 1px solid #99ccff;

  h2 {
    margin: 0;
    color: #000;
    // font-weight: 400;
    background: #fff;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 15px;
    text-align: center;
    font-size: 24px;
  }

  h3 {
    color: #000;
    text-align: center;
    margin-top: 15px;
  }

  .tab-content {
    padding-top: 10px;

    .tab-pane {
      height: 100%;
    }
  }

  ul.check {
    color: #000;
    margin-bottom: 0;

    li {
      &:before {
        color: #fd7d52;
      }
    }
  }

  #select-course {
    .drop {
      overflow-y: scroll !important;
      transform: none !important;
      top: 76px !important;
      max-width: 400px;
      overflow-x: hidden;
    }

    #dropdownMenuButton {
      background: #9c3;
      border: 1px solid #76b51c;
      color: #000;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
      max-width: 400px;
      width: 100%;
      text-align: left;

      &:after {
        float: right;
        content: '\E818';
        border: 0;
        font-family: 'icons';
        font-size: inherit;
        position: inherit;
      }

      &:focus {
        box-shadow: none;
        background-image: none;
        background: darken(#9c3, 5%);
      }
    }
  }

  .card.action {
    &.compliance {
      max-width: 330px;
    }

    &.business {
      max-width: 400px;
    }
  }

  .banner-nav {
    padding: 0;
    margin: 0 auto;
    list-style: none;
    background: linear-gradient(to right, #fe9900 0%, #ffb84e 100%);

    .nav_item {
      &.first {
        display: none;
      }

      &:first-child {
        border: 0;
      }
    }

    .nav-link {
      padding: 0;
      border-top: 1px solid #ebebeb;
      border-bottom: 0;
      cursor: pointer;

      &:first-of-type {
        border-top: 0;
      }

      &.active {
        span {
          color: #fff;
        }
      }
      a {
        color: #000;
        &.active,
        &:hover {
          color: #fff;
          background-color: #007bff;
        }
      }

      span {
        display: block;
        padding: 0 5px 0 30px;
        font:
          600 16px/50px 'Open Sans',
          'Trebuchet MS',
          Verdana,
          Tahoma,
          Helvetica,
          Arial,
          sans-serif;
        position: relative;

        &:before {
          content: '\e820';
          font-family: 'icons';
          font-size: 70%;
          color: #fff;
          position: absolute;
          left: 15px;
        }
      }
    }
  }
}

img.right {
  display: none;
  float: right;
  margin: 10px;
}

.card {
  border-color: #9cf;

  &.action {
    background: #fff url(../images/section_bg.jpg) no-repeat 0 0;
  }
}

#landing {
  .card {
    &.benefits {
      background: #fff;
    }

    &.certs {
      background: #fff url(../images/section_bg.jpg) no-repeat 0 0;
      max-width: 450px;
      margin-right: auto;
      margin-left: auto;

      .dropdown {
        .btn {
          width: 100%;
          text-align: left;
          border-color: #a1c4e5;
          background: none;
          color: #343a40;

          &:hover,
          &:active,
          &:focus {
            background: darken(#fff, 5%);
            box-shadow: none;
          }
        }

        .dropdown-menu {
          width: 100%;
        }
      }

      .certs .btn {
        color: #000;
        font-size: 13.5px;
        width: 100%;
        background: #9fc0e0;
        text-shadow: 0 1px 0 #cbe4ff;
        height: 52px;
        line-height: 15px;

        &:hover {
          background: #b6d2ec;
        }
      }
    }

    h3 {
      vertical-align: middle;
    }

    &.landing-tab {
      .nav-link {
        background: #f0f0f0;
        color: #06c;
        cursor: pointer;

        &:hover,
        &.active {
          background: darken(#f0f0f0, 5%);
        }
      }

      .tab-content {
        background: #f0f0f0;
      }
    }
  }
}

#togglediv {
  background: #004dc1;

  .dropdown-list .btn {
    background: #1465dc;
    background-image: linear-gradient(#3a8aff, #1465dc);
    border: 1px solid #89b9ff;
    color: #fff;
    width: 250px;
    text-align: left;

    &:hover,
    &:active {
      background: #1465dc;
      border-color: #89b9ff;
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
  a {
    cursor: pointer;
  }

  .drop {
    width: 250px;
  }
}

.nav-item {
  flex: 1 1 auto;
  text-align: center;
}

#content {
  &.inner {
    h1 {
      color: #06c;
      text-align: center;
    }

    .post {
      img {
        max-width: 150px;
      }

      .readmore {
        a {
          border: 1px solid #9cf;

          &:hover {
            background: #9cf;
            color: #fff;
          }
        }
      }
    }
  }

  .banner {
    img {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      min-width: 710px;
      width: 100%;
    }

    h2 {
      font-size: 1.3em;
    }

    &.banner.certs h1 {
      color: #06c;
    }
  }

  img.post_img {
    max-width: 200px;
    width: 100%;
  }
}

.field.block {
  margin-bottom: 15px;
}

#course-code {
  width: 100%;
  min-height: 850px;
}

.practice_test {
  width: 90%;
  display: block;
  margin: 40px auto 30px;
  padding: 6px 15px 5px;
  min-width: 250px;
  color: #fff;
  background: #69c;
  text-decoration: none;
  border: 1px solid #008db8;
  border-radius: 5px;
  position: relative;

  img {
    position: absolute;
    margin-top: -41px;
    top: 50%;
    left: 10px;
    display: none;
  }

  &:hover {
    background: darken(#69c, 10%);
    border: 1px solid #f27300;
    color: #fff;
  }
}

.share {
  margin: 15px 0 20px 0;
}

.featherlight.video {
  .featherlight-content {
    overflow: hidden;
  }
}

.dropdown-list .drop {
  &.drop_bottom {
    top: 39px;
  }

  &.drop_top {
    bottom: 39px;
    top: auto;
  }
}

.video_box {
  margin: 5px auto;
  padding-bottom: 56.25%;
  width: 100%;
  position: relative;
  height: 0;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

p.callout {
  background: #f2f3f5;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
}

.top-border {
  border-top: 3px solid #0098da;
}

#headline {
  background: #ca350f;
  border: 1px solid #99ccff;
}

// LANDING PAGE TEST

#banner-wrap {
  &.con-10 {
    background: #222 url(../images/banners/10-con-sm.jpg) no-repeat 50% 50%;
    background-size: cover;
  }

  &.con-10-sp {
    background: #222 url(../images/banners/10-con-sp-sm.jpg) no-repeat 50% 30%;
    background-size: cover;
  }

  &.con-30 {
    background: #222 url(../images/banners/30-con-sm.jpg) no-repeat 50% 30%;
    background-size: cover;
  }

  &.con-30-sp {
    background: #222 url(../images/banners/30-con-sp-sm.jpg) no-repeat 50% 30%;
    background-size: cover;
  }

  &.gen-10 {
    background: #222 url(../images/banners/10-gen-sm.jpg) no-repeat 50% 50%;
    background-size: cover;
  }

  &.gen-10-sp {
    background: #222 url(../images/banners/10-gen-sp-sm.jpg) no-repeat 50% 50%;
    background-size: cover;
  }

  &.gen-30 {
    background: #222 url(../images/banners/30-gen-sm.jpg) no-repeat 50% 30%;
    background-size: cover;
  }

  &.gen-30-sp {
    background: #222 url(../images/banners/30-gen-sp-sm.jpg) no-repeat 50% 25%;
    background-size: cover;
  }
}

#content-wrap.inner-test {
  background: #fff;
}

/* ----------------------------------------------------------------------------
	Business Pages
----------------------------------------------------------------------------- */
#business_pages {
  h1,
  h2,
  h3,
  h4,
  h5,
  a {
    color: #008d96;
  }

  #menutoggle {
    background: #56626b;

    &:hover {
      background: #697b87;
    }
  }

  #nav {
    border-color: #56626b;
    ul {
      background: #8aacb9;

      li {
        a {
          color: #fff;

          &.is-active {
            background: #697b87;
          }
        }
      }
    }
  }

  #nav-wrap {
    border-color: #56626b;
    background: #697b87;
  }

  #nav li a:hover,
  #nav li a.active,
  #nav li:hover span.submenu,
  #nav ul.submenu li a:hover,
  #nav ul.submenu li a.active {
    background: #697b87;
  }

  #header #support a.login {
    background: #697b87;

    &:hover {
      background: #8997a1;
    }
  }

  #banner.home {
    background: #d2e0ea;
    border: 1px solid #333;

    .cta-btn {
      max-width: 170px;
    }

    h2 {
      color: #fff;
      background: #000;
      background: rgba(0, 0, 0, 0.7);
    }

    #togglediv {
      background: #697b87;

      .btn {
        background: #5e7b8e;
        border: 1px solid #b0c5d4;

        &:hover,
        &:active,
        &:focus {
          background: #869dad;
        }
      }
    }

    .banner-nav {
      background: #333;

      .nav-link {
        a {
          color: #fff;

          &:hover {
            background: #8aacb9;
          }
          &.active {
            background: #8aacb9;
          }
        }
      }
    }
  }

  #content {
    &.inner {
      h1 {
        color: #008d96;
      }
    }
  }

  #footer {
    a {
      color: #999;
    }
  }

  .cta-btn.orange {
    color: #fff;
  }

  #aside .shortform,
  #landing .shortform {
    background: #93a8b7;
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #business_pages {
    #header-wrap {
      border-top: 3px solid #697b87;
    }
  }

  #header a.logo img {
    display: block;
  }

  .practice_test {
    width: 70%;
    padding: 6px 15px 5px 130px;

    img {
      display: block;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }

  #header a.logo {
    img {
      display: block;
      float: left;
      margin-right: 15px;
    }

    span.big {
      display: inline-block;
      font-size: 1.9em;
    }

    span.small {
      display: inline-block;
    }
  }

  #banner.home {
    #item-0 {
      background: url(../images/item_bg.jpg) no-repeat right -1600px;
      background-position: right -1720px;
    }

    #item-1,
    #item-2 {
      background: url(../images/item_bg.jpg) no-repeat right 0;
    }

    #item-3,
    #item-4 {
      background: url(../images/item_bg.jpg) no-repeat right -400px;
    }

    #item-5,
    #item-6 {
      background: url(../images/item_bg.jpg) no-repeat right -800px;
    }

    #item-7 {
      background: url(../images/item_bg.jpg) no-repeat right -1200px;
    }

    #item-8 {
      background: url(../images/item_bg.jpg) no-repeat right -2000px;
    }

    &.newyork_banner #item-0 {
      background: url(../images/banner_bg-small.png) no-repeat right 0;
      background-size: cover;
      height: 275px;
    }

    h2 {
      text-align: left;
    }

    h3 {
      text-align: left;
      font-size: 20px;
    }

    ul.check {
      font-size: 1.1em;
    }

    .card.action {
      max-width: 525px;
      height: 75px;

      &.compliance {
        width: 335px;
      }

      &.business {
        height: auto;

        .cta-btn.red {
          margin-top: 0 !important;
        }

        a.learnmore {
          margin-top: 0;
        }
      }

      .cta-btn.red {
        margin-top: -15px !important;
        max-width: 200px;
      }

      a.learnmore {
        margin-top: -10px;
        padding: 0 10px;
        vertical-align: middle;
      }
    }
  }

  #business_pages {
    #banner.home {
      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
      }

      .details {
        background: #000;
        background: rgba(0, 0, 0, 0.7);
        width: 85%;

        ul.check {
          color: #fff;

          li:before {
            color: #93a8b7;
          }
        }
      }

      #item-0 {
        background: url(../images/item_bg2.jpg) no-repeat right -1540px;
      }

      #item-1,
      #item-2 {
        background: url(../images/item_bg2.jpg) no-repeat right 0;
      }

      #item-3,
      #item-4 {
        background: url(../images/item_bg2.jpg) no-repeat right -385px;
      }

      #item-5,
      #item-6 {
        background: url(../images/item_bg2.jpg) no-repeat right -770px;
      }

      #item-7 {
        background: url(../images/item_bg2.jpg) no-repeat right -1155px;
      }
    }

    #content .banner h2 {
      color: #008d96;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #banner.home {
    .tab-content {
      height: 100%;
    }

    #item-0 {
      background: url(../images/item_bg.jpg) no-repeat right -1600px;

      h3,
      p,
      ul.check,
      ul.dropdown {
        margin-left: 0;
      }
    }

    #item-1,
    #item-2 {
      background: url(../images/item_bg.jpg) no-repeat right 0;
    }

    #item-3,
    #item-4 {
      background: url(../images/item_bg.jpg) no-repeat right -400px;
    }

    #item-5,
    #item-6 {
      background: url(../images/item_bg.jpg) no-repeat right -800px;
    }

    #item-7 {
      background: url(../images/item_bg.jpg) no-repeat right -1200px;
    }

    #item-8 {
      background: url(../images/item_bg.jpg) no-repeat right -2000px;
    }

    .card-action {
      width: 480px;

      &.compliance,
      &.business {
        width: 340px;
      }

      .cta-btn {
        max-width: 170px;
      }
    }
  }

  #landing {
    .description {
      .description_container {
        margin-bottom: 0;

        h1 {
          text-align: left;
          font-size: 1.5em;
        }
      }
    }
  }

  img.right {
    display: block;
  }

  #banner-wrap {
    h1 {
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
    }

    &.con-10 {
      background: #222 url(../images/banners/10-con.jpg) no-repeat 50% 50%;
      background-size: cover;
    }

    &.con-10-sp {
      background: #222 url(../images/banners/10-con-sp.jpg) no-repeat 75% 50%;
      background-size: cover;
    }

    &.con-30 {
      background: #222 url(../images/banners/30-con.jpg) no-repeat 50% 50%;
      background-size: cover;
    }

    &.con-30-sp {
      background: #222 url(../images/banners/30-con-sp.jpg) no-repeat 75% 50%;
      background-size: cover;
    }

    &.gen-10 {
      background: #222 url(../images/banners/10-gen.jpg) no-repeat 50% 50%;
      background-size: cover;
    }

    &.gen-10-sp {
      background: #222 url(../images/banners/10-gen-sp.jpg) no-repeat 50% 50%;
      background-size: cover;
    }

    &.gen-30 {
      background: #222 url(../images/banners/30-gen.jpg) no-repeat 50% 50%;
      background-size: cover;
    }

    &.gen-30-sp {
      background: #222 url(../images/banners/30-gen-sp.jpg) no-repeat 75% 50%;
      background-size: cover;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner.home .details {
    width: 65%;
  }

  .card-height {
    height: 70px;
  }

  .card.action.card-height {
    .cta-btn {
      position: relative;
      top: -5px;
    }
  }

  #nav li.callout a {
    background: #0079f2;
  }

  #content {
    .banner {
      border: 1px solid #06c;
      height: 150px;

      h1,
      h2 {
        z-index: 2;
      }

      h1 {
        font-size: 2em;
      }

      h2 {
        color: #06c;
      }

      img {
        display: block;
      }
    }
  }

  #business_pages {
    #nav-wrap {
      background: #333;
      border: none;
    }

    #nav,
    #nav ul {
      background: #333;
    }

    #nav {
      ul {
        li {
          &.callout a {
            background: #697b87;
          }
        }
      }
    }

    #banner.home .details {
      width: 75%;
    }
  }
}
